.App {
  /* text-align: center; */
  /* padding-right: 20px; */
  /* padding-left: 20px; */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  /* color: #61dafb; */
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
  font-style: italic;
}

h1 {
  font-size: 5em;
  margin: 20px;
  font-family: 'Bebas Neue';
}

h3 {
  color: white;
  margin: 10px;
}

p {
  margin: 0;
  color: white;
  font-size: larger;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.metadata * {
  font-size: smaller;
  /* color:rgb(154, 146, 192); */
  color: aliceblue;
}

.profile-box .metadata+.metadata {
  margin-inline-end: 5px;
}

.clickable {
  cursor: pointer;
}

.invisible-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.drop-shadow-box {
  box-shadow: 0px 2px 5px #757575;
}

.profile-box {
  /* background-color: rgb(42, 39, 59); */
  background-color: rgb(39, 39, 39);
  border-radius: 10px;
  /* border: 2px solid rgb(64, 59, 88); */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px;
  min-height: 100px;
  width: 300px;
  /* overflow-y: scroll; */
}

.profile-content {
  display: flex;
  flex-direction: column;
}

.feed-box {
  /* background-color: rgb(48, 48, 48); */
  /* border: 1px solid white; */
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: start;
  align-items: flex-start;
}

.profile-box+.profile-box {
  margin-block-start: 10px;
}

.about-text * {
  color: white;
  font-family: 'Roboto Slab', serif;
  font-size: initial;
  text-align: justify;
}

.about-text p+p {
  margin-top: 10px;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  bottom: 0;
  right: 0;
  margin: 0;
  margin-top: 20px;
}